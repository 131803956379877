<template>
<pre class="space-preserver"> </pre>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.space-preserver {
  display: inline;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font: inherit;
  white-space: normal;
}
</style>
